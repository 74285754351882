<fc-dialog-header [title]="formTitle"></fc-dialog-header>
<fc-dialog-content class="edit-user-vehicle">
  <form>
    <mat-button-toggle-group
      class="navigation-toggle"
      (change)="switchTab($event.value)"
      [value]="activeTab"
    >
      <mat-button-toggle value="range"> Range</mat-button-toggle>
      <mat-button-toggle value="oneDay"> One Day</mat-button-toggle>
      <mat-button-toggle value="ongoing"> Ongoing</mat-button-toggle>
    </mat-button-toggle-group>
    <div class="vehicle-select m-b-16">
      <fc-select
        [formControl]="selectedItemControl"
        [placeholder]="
          formTitle === 'Assign Vehicle' ? 'Select Vehicle' : 'Select Driver'
        "
        [searchPlaceholder]="
          formTitle === 'Assign Vehicle' ? 'Vehicle search' : 'Driver search'
        "
      >
        @for (item of data.itemsList$ | async; track item.id) {
          <fc-option [value]="item.id">
            <ng-container
              *ngTemplateOutlet="vehiclePhoto; context: { $implicit: item }"
            ></ng-container>
            <ng-container
              *ngTemplateOutlet="itemName; context: { $implicit: item }"
            ></ng-container>
          </fc-option>
        }
      </fc-select>
    </div>
    <fc-material-range-picker-input
      *ngIf="activeTab === 'range'"
      class="fc-date-picker"
      [(ngModel)]="dateRange"
      [ngModelOptions]="{ standalone: true }"
      [dateInputFormat]="'MMM. DD YYYY'"
    ></fc-material-range-picker-input>
    <fc-inline-date-picker
      *ngIf="activeTab === 'oneDay'"
      [date]="day"
      (dateChanged)="setOneDay($event)"
      [maxDate]="null"
    >
    </fc-inline-date-picker>
    <fc-inline-date-picker
      *ngIf="activeTab === 'ongoing'"
      [date]="day"
      (dateChanged)="setOngoing($event)"
      [maxDate]="null"
    >
    </fc-inline-date-picker>
  </form>
</fc-dialog-content>
<fc-dialog-footer
  [primaryText]="selectedItemControl.value ? 'Save' : 'Assign'"
  [disabled]="selectedItemControl.value < 0"
  [loading]="data.loading$ | async"
  (primaryClicked)="onSubmit()"
></fc-dialog-footer>
<ng-template #itemName let-option>
  @if (formType === 'vehicle') {
    {{ option.identifier }}
  } @else {
    {{ option.firstName + ' ' + option.lastName }}
  }
</ng-template>
<ng-template #vehiclePhoto let-option>
  <div class="dropdown-image {{ formType }}">
    <ng-container *ngIf="formType === 'vehicle'">
      <fc-image-loader
        [notClickable]="true"
        [currentIndex]="0"
        [thumbWidth]="20"
        [thumbHeight]="16"
        [placeholderSize]="'11px'"
        [photoModels]="[option?.photo]"
      ></fc-image-loader>
    </ng-container>
    <ng-container *ngIf="formType === 'employee'">
      <fc-user-photo
        *ngIf="option?.avatar; else placeholder"
        [employeePhoto]="option?.avatar"
        [width]="20"
        [height]="20"
      ></fc-user-photo>
      <ng-template #placeholder>
        <fc-avatar-placeholder
          [color]="option?.color"
          [fontSize]="10"
          [size]="20"
          [text]="option?.firstName[0] + option?.lastName[0]"
        ></fc-avatar-placeholder>
      </ng-template>
    </ng-container>
  </div>
</ng-template>
